/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat
@include mat.core()

$tda-primary: mat.define-palette(mat.$orange-palette, 500, 100, 900)
$tda-accent: mat.define-palette(mat.$gray-palette, A200, A100, A400)

$tda-theme: mat.define-light-theme($tda-primary, $tda-accent)

@include mat.all-component-themes($tda-theme)
@include mat.all-component-typographies()

//@import '~@aws-amplify/ui-angular/theme.css'
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap')

.amplify-button[data-variation='primary']
  background: mat.get-color-from-palette(mat.$orange-palette, 400)

.amplify-button[data-variation='link']
  color: mat.get-color-from-palette(mat.$gray-palette, 600)

  &:hover
    color: mat.get-color-from-palette(mat.$gray-palette, 900)
    background-color: mat.get-color-from-palette(mat.$orange-palette, 100)
